"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLeasingPhoneNumberForRegion = exports.getLeasingPhoneNumberForState = exports.format = void 0;
const regions_1 = require("./regions");
const format = (phone) => {
    if (!phone) {
        return null;
    }
    let to = phone.replace(/\D/g, '');
    if (to.length === 10) {
        to = `+1${to}`;
    }
    else if (to.length === 11 && to[0] === '1') {
        to = `+${to}`;
    }
    else if (to.length !== 12 || to.substring(0, 2) !== '+1') {
        return null;
    }
    return to;
};
exports.format = format;
const getLeasingPhoneNumberForState = (state) => {
    if (state === null) {
        throw new Error('Missing state.');
    }
    switch (state) {
        case regions_1.STATES.MO:
        case regions_1.STATES_FULL.MO:
            return '+13142933909';
        case regions_1.STATES.NC:
        case regions_1.STATES_FULL.NC:
            return '+17042597856';
        case regions_1.STATES.CA:
        case regions_1.STATES_FULL.CA:
            return '+12132972604';
        case regions_1.STATES.GA:
        case regions_1.STATES_FULL.GA:
            return '+14043902224';
        case regions_1.STATES.IN:
        case regions_1.STATES_FULL.IN:
            return '+14632175705';
        case regions_1.STATES.AL:
        case regions_1.STATES_FULL.AL:
            return '+12566432926';
        default:
            throw new Error(`Cannot get phone number from state: ${state}.`);
    }
};
exports.getLeasingPhoneNumberForState = getLeasingPhoneNumberForState;
const getLeasingPhoneNumberForRegion = (region) => {
    if (region === null) {
        throw new Error('Missing state.');
    }
    switch (region) {
        case regions_1.REGIONS.ST_LOUIS:
            return '+13142933909';
        case regions_1.REGIONS.CHARLOTTE:
            return '+17042597856';
        case regions_1.REGIONS.LOS_ANGELES:
            return '+12132972604';
        case regions_1.REGIONS.ATLANTA:
            return '+14043902224';
        case regions_1.REGIONS.INDIANAPOLIS:
            return '+14632175705';
        case regions_1.REGIONS.HUNTSVILLE:
            return '+12566432926';
        default:
            throw new Error(`Cannot get phone number from region: ${region}.`);
    }
};
exports.getLeasingPhoneNumberForRegion = getLeasingPhoneNumberForRegion;
