"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.legalSigner = exports.legalEmail = exports.companyAddresses = exports.companyLegalName = exports.companyDomain = exports.companyName = exports.getCompanyAddressOneLiner = exports.LEGAL_SIGNER = exports.LEGAL_EMAIL = exports.COMPANY_ADDRESSES = exports.COMPANY_LEGAL_NAME = exports.COMPANY_DOMAIN = exports.COMPANY_NAME = void 0;
exports.COMPANY_NAME = 'Up&Up';
exports.COMPANY_DOMAIN = 'upandup.co';
exports.COMPANY_LEGAL_NAME = 'Homevest Inc.';
exports.COMPANY_ADDRESSES = {
    nyc: {
        addressLine1: '243 Canal Street',
        addressLine2: 'Floor 6',
        city: 'New York',
        state: 'NY',
        zipCode: '10013'
    },
    stl: {
        addressLine1: '8001 Clayton Road',
        city: 'St. Louis',
        state: 'MO',
        zipCode: '63117'
    }
};
exports.LEGAL_EMAIL = `legal@${exports.COMPANY_DOMAIN}`;
exports.LEGAL_SIGNER = 'Michael Wong';
const getCompanyAddressOneLiner = (office = 'nyc') => {
    const address = exports.COMPANY_ADDRESSES[office];
    if (!address)
        return '';
    return [
        address.addressLine1,
        address.addressLine2,
        address.city,
        address.state,
        address.zipCode
    ].join(', ');
};
exports.getCompanyAddressOneLiner = getCompanyAddressOneLiner;
/** Warning: to be deprecated */
exports.companyName = exports.COMPANY_NAME;
/** Warning: to be deprecated */
exports.companyDomain = exports.COMPANY_DOMAIN;
/** Warning: to be deprecated */
exports.companyLegalName = exports.COMPANY_LEGAL_NAME;
/** Warning: to be deprecated */
exports.companyAddresses = exports.COMPANY_ADDRESSES;
/** Warning: to be deprecated */
exports.legalEmail = exports.LEGAL_EMAIL;
/** Warning: to be deprecated */
exports.legalSigner = exports.LEGAL_SIGNER;
