"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATES_FULL = exports.STATES = exports.SEARCHABLE_REGION_CODES = exports.REGIONS = void 0;
exports.REGIONS = {
    ATLANTA: 'atlanta-ga',
    CHARLOTTE: 'charlotte-nc',
    DALLAS: 'dallas-tx',
    HUNTSVILLE: 'huntsville-al',
    INDIANAPOLIS: 'indianapolis-in',
    LOS_ANGELES: 'los-angeles-ca',
    PHOENIX: 'phoenix-az',
    RALEIGH: 'raleigh-nc',
    ST_LOUIS: 'st-louis-mo',
    TAMPA: 'tampa-fl'
};
exports.SEARCHABLE_REGION_CODES = {
    [exports.REGIONS.ATLANTA]: 'Atlanta',
    [exports.REGIONS.CHARLOTTE]: 'Charlotte',
    [exports.REGIONS.DALLAS]: 'Dallas',
    [exports.REGIONS.HUNTSVILLE]: 'Huntsville',
    [exports.REGIONS.INDIANAPOLIS]: 'Indianapolis',
    [exports.REGIONS.LOS_ANGELES]: 'Los Angeles',
    [exports.REGIONS.PHOENIX]: 'Phoenix',
    [exports.REGIONS.RALEIGH]: 'Raleigh',
    [exports.REGIONS.TAMPA]: 'Tampa'
};
exports.STATES = {
    AL: 'AL',
    AZ: 'AZ',
    CA: 'CA',
    GA: 'GA',
    IN: 'IN',
    MO: 'MO',
    NC: 'NC'
};
exports.STATES_FULL = {
    AL: 'Alabama',
    AZ: 'Arizona',
    CA: 'California',
    GA: 'Georgia',
    IN: 'Indiana',
    MO: 'Missouri',
    NC: 'North Carolina'
};
