"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.leadStatuses = exports.sources = exports.accountTypes = exports.REQUIRED_INCOME_RENT_MULTIPLE = exports.RENTAL_SITE_SOURCES = exports.RENTAL_SITE_FEED_SPECIFICATIONS = exports.RENTAL_SITE_ACCOUNT_TYPES = exports.LEAD_STATUSES = exports.SOURCES = exports.ACCOUNT_TYPES = void 0;
exports.ACCOUNT_TYPES = {
    homevest: 'homevest',
    burner: 'burner'
};
exports.SOURCES = {
    zillow: 'zillow',
    zumper: 'zumper'
};
exports.LEAD_STATUSES = {
    notContacting: 'not_contacting',
    pendingContact: 'pending_contact',
    invalidContactInfo: 'invalid_contact_info',
    contacted: 'contacted',
    propertyNotFound: 'property_not_found'
};
exports.RENTAL_SITE_ACCOUNT_TYPES = {
    BURNER: 'burner',
    EXTERNAL: 'external',
    INTERNAL: 'internal'
};
exports.RENTAL_SITE_FEED_SPECIFICATIONS = {
    HOT_PADS: 'hot_pads',
    RENTLY: 'rently',
    ZILLOW: 'zillow'
};
exports.RENTAL_SITE_SOURCES = {
    APARTMENTS_DOT_COM: 'apartments.com',
    RENTALBE: 'rentable',
    RENTALS_DOT_COM: 'rentals.com',
    RENTLY: 'rently',
    TENANT_TURNER: 'tenant_turner',
    ZILLOW: 'zillow',
    ZUMPER: 'zumper'
};
exports.REQUIRED_INCOME_RENT_MULTIPLE = 3;
/** Warning: to be deprecated */
exports.accountTypes = exports.ACCOUNT_TYPES;
/** Warning: to be deprecated */
exports.sources = exports.SOURCES;
/** Warning: to be deprecated */
exports.leadStatuses = exports.LEAD_STATUSES;
