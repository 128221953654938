import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Input,
  useToast,
  FormErrorMessage,
  Stack,
  Divider,
  Text
} from '@chakra-ui/react'
import ReactSelect from 'react-select'
import { useMutation } from '@tanstack/react-query'
import { constructionProjects } from '@homevest/utils'
import { CAPABILITY_TYPES } from '@homevest/utils/capabilities'
import {
  useOwnedPortfolioHomesQuery,
  useUpupAdminsWithCapabilitiesByCapabilityTypeQuery
} from 'graphql/generated'
import { startCase } from 'lodash'
import { ConstructionProjectCreatePayload } from '@homevest/types/construction-projects'

import {
  validateConstructionProject,
  createConstructionProject,
  mapProjectTypeToLabel
} from 'lib/construction-projects'
import { formatUTCDate } from 'lib/date-time'
import CurrencyInput from 'components/TailwindUIToolkit/Inputs/CurrencyInput'

const { PROJECT_TYPES, PROJECT_STATUSES, PROJECT_SUBSTATUSES, PROJECT_TRACKING_STATUSES } =
  constructionProjects

const CreateProjectModal = ({ refetchProjects }: { refetchProjects: () => void }) => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [project, setProject] = useState<Partial<ConstructionProjectCreatePayload>>({})

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [{ data: portfolioHomesData }] = useOwnedPortfolioHomesQuery()
  const [{ data: adminData }] = useUpupAdminsWithCapabilitiesByCapabilityTypeQuery({
    variables: {
      capability_types: [CAPABILITY_TYPES.CONSTRUCTION_PROJECT_ASSIGNEE]
    }
  })

  const toast = useToast()

  const handleSubmit = async () => {
    const { isValid, errors } = validateConstructionProject(
      project as ConstructionProjectCreatePayload
    )

    if (!isValid) {
      setErrors(errors)
      toast({
        title: 'Error!',
        description:
          'Unable to create construction project! Please ensure that all fields are filled out correctly.',
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true
      })
      return
    }

    try {
      await mutateAsync(project as ConstructionProjectCreatePayload)
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true
      })
      return
    }

    toast({
      title: 'Success!',
      description: 'Construction project created successfully!',
      status: 'success',
      duration: 5000,
      position: 'bottom-right',
      isClosable: true
    })
    refetchProjects()
    onClose()
  }

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createConstructionProject
  })

  return (
    <>
      <Button onClick={onOpen} colorScheme='teal'>
        Create Project +
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth='fit-content' height='fit-content'>
          <ModalHeader>Create a Project</ModalHeader>
          <Divider />
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={10} my={5} mx={7}>
              <Text>
                Fill out the fields below to create a new construction project. You can edit the
                status and other fields later.
              </Text>
              <div className='md-gap-4 flex flex-col md:flex-row md:justify-between'>
                <div className='flex flex-col gap-4'>
                  <FormControl isRequired isInvalid={!!errors.portfolio_home_id}>
                    <FormLabel>Property</FormLabel>
                    <ReactSelect
                      isSearchable
                      isClearable
                      onChange={(value) => {
                        setProject({ ...project, portfolio_home_id: value?.value } as any)
                      }}
                      options={portfolioHomesData?.portfolio_homes
                        .sort((a, b) => {
                          const address1 = a.home.address.display_line_1!
                          const address2 = b.home.address.display_line_1!
                          return address1.localeCompare(address2)
                        })
                        .map((portfolioHome) => ({
                          label: `${portfolioHome.home.address.display_line_1} - ${portfolioHome.home.market?.display_name}`,
                          value: portfolioHome.id
                        }))}
                    />
                    <FormErrorMessage>{errors.portfolio_home_id}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors.type}>
                    <FormLabel>Type</FormLabel>
                    <Select
                      value={project.type ?? ''}
                      minW={'-moz-max-content'}
                      onChange={(e) => setProject({ ...project, type: e.target.value })}
                    >
                      <option value='' disabled>
                        Select a project type
                      </option>
                      {Object.values(PROJECT_TYPES).map((type) => (
                        <option key={type} value={type}>
                          {mapProjectTypeToLabel(type)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.type}</FormErrorMessage>
                  </FormControl>
                  <FormControl isRequired isInvalid={!!errors.status}>
                    <FormLabel>Status</FormLabel>
                    <Select
                      value={project.status ?? ''}
                      onChange={(e) => setProject({ ...project, status: e.target.value })}
                    >
                      <option value='' disabled>
                        Select a project status
                      </option>
                      {Object.values(PROJECT_STATUSES).map((status) => (
                        <option key={status} value={status}>
                          {startCase(status)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.status}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Substatus</FormLabel>
                    <Select
                      value={project.substatus ?? ''}
                      onChange={(e) => setProject({ ...project, substatus: e.target.value })}
                    >
                      <option value='' disabled>
                        Select a project substatus
                      </option>
                      {Object.values(PROJECT_SUBSTATUSES).map((status) => (
                        <option key={status} value={status}>
                          {startCase(status)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>On Track?</FormLabel>
                    <Select
                      value={project.tracking_status ?? ''}
                      onChange={(e) => setProject({ ...project, tracking_status: e.target.value })}
                    >
                      <option value='' disabled>
                        Select a status
                      </option>
                      {Object.values(PROJECT_TRACKING_STATUSES).map((status) => (
                        <option key={status} value={status}>
                          {startCase(status)}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{errors.tracking_status}</FormErrorMessage>
                  </FormControl>
                </div>
                <div className='flex flex-col gap-4'>
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                      type='date'
                      value={project.start_date ? formatUTCDate(project.start_date) : ''}
                      onChange={(e) => setProject({ ...project, start_date: e.target.value })}
                    />
                  </FormControl>
                  <FormControl isInvalid={!!errors.end_date}>
                    <FormLabel>End Date</FormLabel>
                    <Input
                      type='date'
                      value={project.end_date ? formatUTCDate(project.end_date) : ''}
                      onChange={(e) => setProject({ ...project, end_date: e.target.value })}
                    />
                    <FormErrorMessage>{errors.end_date}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.estimated_cost}>
                    <FormLabel>Estimated Cost</FormLabel>
                    <CurrencyInput
                      value={project.estimated_cost ?? ''}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          estimated_cost: e.target.value ? Number(e.target.value) : null
                        })
                      }
                    />
                    <FormErrorMessage>{errors.estimated_cost}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.actual_cost}>
                    <FormLabel>Actual Cost</FormLabel>
                    <CurrencyInput
                      value={project.actual_cost ?? ''}
                      onChange={(e) =>
                        setProject({
                          ...project,
                          actual_cost: e.target.value ? Number(e.target.value) : null
                        })
                      }
                    />
                    <FormErrorMessage>{errors.actual_cost}</FormErrorMessage>
                  </FormControl>

                  <FormControl>
                    <FormLabel>Assigned Admin</FormLabel>
                    <Select
                      value={project.assigned_to_admin_id ?? ''}
                      onChange={(e) =>
                        setProject({ ...project, assigned_to_admin_id: e.target.value || null })
                      }
                    >
                      <option value='' disabled>
                        Select an admin
                      </option>
                      {adminData?.admins.map((admin) => (
                        <option key={admin.id} value={admin.id}>
                          {admin.first_name} {admin.last_name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <Button
                className='mb-2'
                onClick={handleSubmit}
                colorScheme='teal'
                isLoading={isLoading}
              >
                Create Project
              </Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateProjectModal
