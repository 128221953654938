import React, { useState } from 'react'
import { assign, startCase } from 'lodash'
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  useToast,
  UseToastOptions,
  Textarea
} from '@chakra-ui/react'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { formatMoney } from 'lib/numbers'
import { Link } from 'ui'

export const successToast = (toast: ReturnType<typeof useToast>, options: UseToastOptions) => {
  const defaultOptions = {
    title: 'Success',
    description: 'Success',
    status: 'success',
    duration: 2000,
    position: 'bottom-right',
    isClosable: true
  }
  toast(assign(defaultOptions, options))
}

export const errorToast = (toast: ReturnType<typeof useToast>, options: UseToastOptions) => {
  const defaultOptions = {
    title: 'Error',
    description: 'Error',
    status: 'error',
    duration: 5000,
    position: 'bottom-right',
    isClosable: true
  }
  toast(assign(defaultOptions, options))
}

export const EditableDate: React.FC<{
  title: string
  value?: string
  isEditing: boolean
  setValue: (x?: string) => void
  isRequired?: boolean
  isDisabled?: boolean
  displayFormatter: (value: string | undefined) => React.ReactNode
}> = ({ title, value, isEditing, setValue, isRequired, isDisabled, displayFormatter }) => {
  // Track component's value in a local state
  // to avoid parent component re-rendering on every keystroke
  // (which makes the user unable to type out a full year)
  const [localValue, setLocalValue] = useState(value)
  const displayTitle = title + ': '

  if (isEditing) {
    return (
      <FormControl isRequired={isRequired}>
        <HStack>
          <FormLabel flex={1}>{title}</FormLabel>
          <Input
            flex={0.85} // this makes the date input vertically aligned with the other inputs
            size='sm'
            type='date'
            disabled={isDisabled || !isEditing}
            value={localValue ?? ''}
            onChange={(e) => setLocalValue(e.target.value)}
            onBlur={() => setValue(localValue)} // onBlur = user has clicked away from the input
          />
        </HStack>
      </FormControl>
    )
  }

  return (
    <HStack>
      <Text flex={1}>{displayTitle}</Text>
      <div className='flex flex-1'>{displayFormatter(value)}</div>
    </HStack>
  )
}

export const EditableNumber: React.FC<{
  title: string
  value?: number
  isEditing: boolean
  setValue: (x?: number) => void
  isRequired?: boolean
  isDisabled?: boolean
}> = ({ title, value, isEditing, setValue, isRequired, isDisabled }) => {
  const displayTitle = title + ': '
  const displayNumber = value ?? ''

  if (isEditing) {
    return (
      <FormControl isRequired={isRequired}>
        <HStack>
          <FormLabel flex={1}>{title}</FormLabel>
          <InputGroup flex={1}>
            <InputLeftAddon h='fill'>$</InputLeftAddon>
            <Input
              size='sm'
              type='number'
              disabled={isDisabled || !isEditing}
              value={displayNumber}
              onChange={(e) =>
                e.target.value === '' ? setValue(undefined) : setValue(Number(e.target.value))
              }
            />
          </InputGroup>
        </HStack>
      </FormControl>
    )
  }

  return (
    <HStack>
      <Text flex={1}>{displayTitle}</Text>
      <Text flex={1}>{displayNumber ? formatMoney(displayNumber, 2, '$') : displayNumber}</Text>
    </HStack>
  )
}

export const EditableDropdown: React.FC<{
  title: string
  isEditing: boolean
  editOptions: Record<string, string>
  value?: string
  setValue: (x?: string) => void
  isRequired?: boolean
  isDisabled?: boolean
  valueFormatter?: (val: string) => string
}> = ({
  title,
  isEditing,
  editOptions,
  value,
  setValue,
  isRequired,
  isDisabled,
  valueFormatter = startCase
}) => {
  const displayTitle = title + ': '

  if (isEditing) {
    return (
      <FormControl isRequired={isRequired}>
        <HStack>
          <FormLabel flex={1}>{title}</FormLabel>
          <Select
            flex={1}
            size='sm'
            disabled={isDisabled || !isEditing}
            value={value ?? ''}
            onChange={(e) =>
              e.target.value === '' ? setValue(undefined) : setValue(e.target.value)
            }
          >
            <option disabled key='' value=''>
              Select {title}
            </option>
            {value && !Object.keys(editOptions).includes(value) && (
              <option key={value} value={value}>
                {valueFormatter(value)}
              </option>
            )}
            {Object.keys(editOptions).map((value) => (
              <option key={value} value={value}>
                {valueFormatter(value)}
              </option>
            ))}
          </Select>
        </HStack>
      </FormControl>
    )
  }

  return (
    <HStack>
      <Text flex={1}>{displayTitle}</Text>
      <Box flex={1}>{mapContentToBadge(value ?? '', { formatFn: valueFormatter })}</Box>
    </HStack>
  )
}

export const EditableText: React.FC<{
  title: string
  value?: string
  isEditing: boolean
  setValue: (x?: string) => void
  isRequired?: boolean
  isDisabled?: boolean
}> = ({ title, value, isEditing, setValue, isRequired, isDisabled }) => {
  const displayTitle = title + ': '

  if (isEditing) {
    return (
      <FormControl isRequired={isRequired}>
        <HStack align='flex-start'>
          <FormLabel flex={1}>{title}</FormLabel>
          <Textarea
            flex={1}
            size='sm'
            disabled={isDisabled || !isEditing}
            value={value ?? ''}
            onChange={(e) =>
              e.target.value === '' ? setValue(undefined) : setValue(e.target.value)
            }
          />
        </HStack>
      </FormControl>
    )
  }

  return (
    <HStack>
      <Text flex={1}>{displayTitle}</Text>
      <Text flex={1}>{value ?? ''}</Text>
    </HStack>
  )
}

export const ImmutableText: React.FC<{
  title: string
  value?: string
}> = ({ title, value }) => (
  <HStack>
    <Text flex={1}>{title + ': '}</Text>
    <Text flex={1}>{value ?? ''}</Text>
  </HStack>
)

export const ImmutableLink: React.FC<{
  title: string
  linkLabel: string
  href?: string | null
}> = ({ title, linkLabel, href }) => (
  <HStack>
    <Text flex={1}>{title + ': '}</Text>
    <Box flex={1}>
      {href ? (
        <Link useRouter isExternal showIcon to={href}>
          {linkLabel}
        </Link>
      ) : (
        '-'
      )}
    </Box>
  </HStack>
)

export const ImmutableBadge: React.FC<{
  title: string
  value?: string
  formatFn?: (value: string) => string
}> = ({ title, value, formatFn = startCase }) => (
  <HStack>
    <Text flex={1}>{title + ': '}</Text>
    <Text flex={1}>{mapContentToBadge(value ?? '', { formatFn })}</Text>
  </HStack>
)
