"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentUTCDate = exports.getTimezoneFromState = exports.isSameDay = exports.isEndOfMonth = exports.formatISODate = exports.TIME_ZONES = exports.DATE_FORMATS = void 0;
const date_fns_tz_1 = require("date-fns-tz");
const moment = require('moment-timezone');
const regions_1 = require("./regions");
exports.DATE_FORMATS = Object.freeze({
    READABLE_DATE: 'MMM Do, YYYY',
    YEAR_MONTH_DAY: 'YYYY-MM-DD'
});
exports.TIME_ZONES = Object.freeze({
    CENTRAL: 'America/Chicago',
    EASTERN: 'America/New_York',
    MOUNTAIN: 'America/Denver',
    PACIFIC: 'America/Los_Angeles'
});
const formatISODate = (date, format = exports.DATE_FORMATS.YEAR_MONTH_DAY) => {
    // This isMoment check is very important because it preserves
    // the timezones of any passed in dates that are already Moments
    return moment.isMoment(date) ? date.format(format) : moment(date).format(format);
};
exports.formatISODate = formatISODate;
const isEndOfMonth = (date) => {
    return moment(date).isSame(moment(date).endOf('month'), 'day');
};
exports.isEndOfMonth = isEndOfMonth;
const isSameDay = (date, secondDate) => {
    return moment(date).isSame(secondDate, 'day');
};
exports.isSameDay = isSameDay;
const getTimezoneFromState = (state) => {
    switch (state) {
        case regions_1.STATES.AL:
        case regions_1.STATES_FULL.AL:
        case regions_1.STATES.MO:
        case regions_1.STATES_FULL.MO:
            return exports.TIME_ZONES.CENTRAL;
        case regions_1.STATES.CA:
        case regions_1.STATES_FULL.CA:
            return exports.TIME_ZONES.PACIFIC;
        case regions_1.STATES.IN:
        case regions_1.STATES_FULL.IN:
        case regions_1.STATES.GA:
        case regions_1.STATES_FULL.GA:
        case regions_1.STATES.NC:
        case regions_1.STATES_FULL.NC:
            return exports.TIME_ZONES.EASTERN;
        default:
            throw new Error(`Cannot get timezone from state: ${state}.`);
    }
};
exports.getTimezoneFromState = getTimezoneFromState;
const getCurrentUTCDate = () => {
    // date-fns does not appear to have a great way to get the current date in
    // UTC regardless of what timezone this code is running in, so we're using moment
    // plus a hack to convert the date back into a native Date object. We should consider
    // replacing this with date-fns or another library in the future.
    return (0, date_fns_tz_1.toDate)(moment().utc().format(), { timeZone: 'UTC' });
};
exports.getCurrentUTCDate = getCurrentUTCDate;
